import { bootstrap } from 'bootstrap';
import { slick } from 'slick-carousel';
import { lazyLoad } from 'jquery-lazy';
import { gsap } from "gsap/dist/gsap";
import { masonry } from "masonry-layout/dist/masonry.pkgd";
import { imagesLoaded } from "imagesloaded/imagesloaded.pkgd";
import { select2 } from "select2/dist/js/select2";
import { detect } from 'detect-browser';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
//import { ScrollSmoother } from "gsap/dist/ScrollSmoother";
import { MorphSVGPlugin } from "gsap/dist/MorphSVGPlugin";
import { data } from 'jquery';



var app = (function() {
    var init = function () {

        // Bootstrap tooltips
        $('[data-bs-toggle="tooltip"]').tooltip();

        $('.gallery.featured').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            asNavFor: '.gallery.carousel',
            lazyLoad: 'ondemand',
            prevArrow: '<div class="prevArrow"><i class="fa-light fa-2x fa-chevron-left"></i></div>',
            nextArrow: '<div class="nextArrow"><i class="fa-light fa-2x fa-chevron-right"></i></div>',
        });

        $('.gallery.carousel').slick({
            adaptiveHeight: true,
            arrows: false,
            lazyLoad: 'ondemand',
            slidesToShow: 5,
            asNavFor: '.gallery.featured',
            focusOnSelect: true,
        });

        $('#our_story_rotator').slick({
            slidesToShow: 1,
            draggable: false,
            arrows: true,
            dots: true,
            mobileFirst: true,
            prevArrow: '<div class="prevArrow"><i class="fa-light fa-2x fa-chevron-left"></i></div>',
            nextArrow: '<div class="nextArrow"><i class="fa-light fa-2x fa-chevron-right"></i></div>',
            responsive: [
                {
                    breakpoint: 1399,
                    settings: {
                        centerMode: true,
                        centerPadding: '275px',
                    }
                },
                {
                    breakpoint: 1199,
                    settings: {
                        centerMode: true,
                        centerPadding: '235px',
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        centerMode: true,
                        centerPadding: '230px',
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        centerMode: true,
                        centerPadding: '190px',
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        centerMode: true,
                        centerPadding: '170px',
                    }
                },

            ]
        });

    };

    var lazyload = function () {
        return $("img.lazy").Lazy({
            defaultImage: '/themes/wow-mobile/assets/images/placeholder.png',
        });
    }

    var animations = function() {
        gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);

        let animations = {
            fadeIn: {
                from: {opacity:0},
                to: {opacity:1, duration:1, scrollTrigger: {start: "top bottom"}},
            },
            fadeInLeft: {
                from: {opacity:0, left: 200},
                to: {opacity: 1, left: 0, duration: 1, scrollTrigger: {start: "top bottom"}}
            },
            fadeInRight: {
                from: {opacity:0, right: 200},
                to: {opacity: 1, right: 0, duration: 1, scrollTrigger: {start: "top bottom"}}
            },
            fadeInUp: {
                from: {opacity:0, top: 200},
                to: {opacity: 1, top: 0, duration: 1, scrollTrigger: {start: "top bottom"}},
            },
            fadeInDown: {
                from: {opacity:0, bottom: 200},
                to: {opacity: 1, bottom: 0, duration: 1, scrollTrigger: {start: "top bottom"}},
            },
        };

        let staggerAnimations = {
            staggerfadeInDown: {
                set: {opacity: 0, scale: 0.5, top:200},
                to: {opacity: 1, scale: 1, top:0, stagger: 0.1, duration: 0.5}
            },
            staggerFadeIn: {
                set: {opacity: 0, scale: 0.5},
                to: {opacity: 1, scale: 1, stagger: 0.1, duration: 0.5}
            }
        };

        ScrollTrigger.matchMedia({

            // large
            "(min-width: 992px)": function() {
                $('body').addClass('animation-enabled');
                $('body').removeClass('animation-disabled');
                $.each(animations , function( key, animation ) {
                    $('.'+key).each(function() {
                        animation.to.scrollTrigger.trigger = $(this);
                        //animation.scrollTrigger.markers = true;
                        let dataConfig = getGsapAttributes($(this).data());
                        let animationToConfig = {...animation.to, ...dataConfig};

                        gsap.fromTo($(this), animation.from, animationToConfig );
                    });
                });
            },

            // small
            "(max-width: 992px)": function() {
                $('body').removeClass('animation-enabled');
                $('body').addClass('animation-disabled');

                $.each(animations , function( key, animation ) {
                    $('.'+key).each(function() {
                        let dataConfig = getGsapAttributes($(this).data());
                        let animationToConfig = {...animation.to, ...dataConfig};

                        gsap.set($(this), animationToConfig );
                    });
                });
            },

        });



        $.each(staggerAnimations , function( key, animation ) {
            $('.'+key).each(function() {
                let $children = $(this).children();

                let dataConfig = getGsapAttributes($(this).data());
                let animationConfig = {...animation, ...dataConfig};

                ScrollTrigger.matchMedia({

                    // large
                    "(min-width: 992px)": function() {
                        gsap.set($children, animationConfig.set);
                        ScrollTrigger.batch($children, {
                            onEnter: batch => {
                                gsap.to(batch, animationConfig.to)
                            },
                            onEnterBack: false
                        });
                    }
                });
            });
        });
    };

    function getGsapAttributes($attributes) {
        var gsapAttributes = {};
        for (var property in $attributes) {
            if (property.startsWith("gsap")){
                var propertyName = property.substring(4);
                propertyName =  propertyName.charAt(0).toLowerCase() + propertyName.slice(1)
                gsapAttributes = { ...gsapAttributes, [propertyName]: $attributes[property]};
            }

        }
        return gsapAttributes;
    }

    var blobs = function() {

        $( ".animated-blobs" ).each(function( index ) {
            var $svg = $(this);
            var $groups = $svg.find('g');

            ScrollTrigger.matchMedia({

                // large
                "(min-width: 992px)": function() {
                    if (!$('body').hasClass('blobs-disabled')) {
                        $groups.each(function() {
                            var tl = gsap.timeline({
                                defaults: {duration: 20},
                                repeat: -1,
                                repeatRefresh: true
                            });

                            var $paths = $(this).find('path');

                            $paths.each(function( index ) {
                                var nextIndex = (index++ > $paths.length--) ?  0 : index++;
                                tl.add(
                                    gsap.to($paths[0], {
                                        duration: function(){ return gsap.utils.random(10, 30) },
                                        x: "+=" + function(){ return gsap.utils.random(-100, 100) },
                                        y: "+=" + function(){ return gsap.utils.random(-100, 100) },
                                        rotation: "+=random(-5, 5)",
                                        scale: "+=random(-0.02, 0.02)",
                                        morphSVG: {
                                            shape: $paths[nextIndex]
                                        },
                                        ease: "sine.inOut"
                                    }), ">"
                                );
                            });
                        });
                    }
                }
            });
        });
    };

    return {
        init: init,
        animations: animations,
        blobs: blobs,
        lazyload: lazyload,
    };

})();

$(function() {

    const browser = detect();

    if (browser) {
        $('body').addClass(browser.name.replace(' ', '-'));
        $('body').addClass(browser.version.replace(' ', '-'));
        $('body').addClass(browser.os.replace(' ', '-'));

        switch (browser.name) {
            case 'firefox':
                $('body').addClass('blobs-disabled');
                break;
        }
    }

    app.init();
    app.lazyload();
    app.animations();
    app.blobs();
    $('body').addClass('loaded');

    toggleFilterAccordions();

    $( window ).resize(function() {
        toggleFilterAccordions();
    });



    $('#accessories-accordion-header').on('click', function(e) {
        $(".catalog-carousel").slick("refresh");
    });

    if($('.catalog-carousel').length) {
        var slides_to_show = 4;

        if($('.catalog-carousel').data('slides_to_show')){
            slides_to_show = $('.catalog-carousel').data('slides_to_show');
        }

        $('.catalog-carousel').slick({
            infinite: true,
            arrows: false,
            dots: true,
            slidesToShow: slides_to_show,
            slidesToScroll: slides_to_show,
            lazyLoad: 'ondemand',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }

    var loadCatalogTimeout;
    var catalogPage = 1;
    function loadCatalog(){

        // Reset the timer
        clearTimeout(loadCatalogTimeout)

        // Start the timer
        loadCatalogTimeout = setTimeout(function(){
            renderCatalog();
        }, 750);
    }

    function renderCatalog(updateURL = true) {

        // Set up loading state
        $(".catalog .catalog-container .loading-screen").removeClass('d-none');
        $(".filter-dropdown").prop( "disabled", true );
        $(".catalog .reset-filters").prop( "disabled", true );
        $('.catalog .filters .filter-group input').prop( "disabled", true );

        // Compile filters & parameters
        var data = {};
        data.filters = getCatalogFilters();
        data.posts_per_page = $("#posts_per_page").val();
        data.sort_order = $("#sort_order").val();
        data.compatible_with = $("#compatible_with").val();
        data.page = catalogPage;



        // Send request to backend
        $.request('onGetCatalog', {
            data: data,
            complete: function(data) {
                catalogPage = data.params.page;
                if (updateURL) {
                    updateCatalogURL(data.params);
                }


                // Reinit elements
                app.lazyload();
                $('[data-bs-toggle="tooltip"]').tooltip();
                if ($('.catalog-item').length) {
                    ScrollTrigger.matchMedia({
                        // large
                        "(min-width: 992px)": function() {
                            gsap.set(".catalog-item", {opacity: 0, scale: 0.5, top:200});
                            ScrollTrigger.batch(".catalog-item", {
                                onEnter: batch => {
                                    gsap.to(batch, {
                                        opacity: 1, scale: 1, top:0, stagger: 0.1,
                                    })
                                },
                                onEnterBack: false,
                                start: "-250px bottom"
                            });
                        }
                    });
                }

                // Remove loading state
                $(".catalog .catalog-container .loading-screen").addClass('d-none');
                $(".filter-dropdown").prop( "disabled", false );
                $(".catalog .reset-filters").prop( "disabled", false );
                $('.catalog .filters .filter-group input').prop( "disabled", false );

                // Set up listener for pagination
                if ($('.catalog .pagination').length) {
                    $(".catalog .pagination .page-link").on('click', function(){
                        $(".catalog .pagination .page-link").removeClass('active');
                        catalogPage = $(this).data('page');
                        loadCatalog();
                        document.querySelector('.catalog-container').scrollIntoView({
                            behavior: 'smooth'
                        });
                    });
                }

            }
        });
    }

    function updateCatalogURL(dataParameters){
        var url = window.location.href.split('?')[0];
        var parameters = [];

        $.each( dataParameters, function( key, value ) {
            if (value) {
                if (key == 'filters') {
                    $.each( value, function( filter, filter_values ) {
                        if (filter_values) {
                            parameters.push(filter + "=" + filter_values);
                        }
                    });
                } else {
                    parameters.push(key + "=" + value);
                }
            }
        });

        var newUrl = url + '?' + parameters.join('&');
        window.history.pushState("load", null, newUrl);
    }

    function setCatalogFiltersFromURL() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        $.each( params, function( key, value ) {
            if (key == 'page') {
                catalogPage = value;
            } else {
                var values = value.split(',');
                values.forEach((element) => {
                    $('select[name="' + key + '"]').val(element);
                    $('.filter-group[data-filter-group="' + key + '"] input[value="' + element + '"]').prop('checked', true);
                });
            }
        });

    }

    if ($('.catalog').length) {

        setCatalogFiltersFromURL();
        renderCatalog(false);

        $(window).on("popstate", function () {
            console.log(history.state);
            if (history.state) {
                loadCatalog();
            } else {
                $(".catalog .filters .filter-group input").prop('checked', false);
                loadCatalog();
            }
        });

        if($(".filter-dropdown").length){
            $(".filter-dropdown:not(.search)").select2({
                theme: "bootstrap-5",
                width: '100%',
                minimumResultsForSearch: Infinity,
            });

            $(".filter-dropdown.search").select2({
                theme: "bootstrap-5",
                width: '100%',
            });
        }

        $(".catalog .reset-filters").on('click', function() {
            $(".catalog .filters .filter-group input").prop('checked', false);
            loadCatalog();
        });

        $(".catalog .filter-dropdown").on('change', function(){
            loadCatalog();
        });

        $(".catalog .filters .filter-group input").on('change', function(){
            if ($(this).parents('.device-sizes').length) {
                var checked = false;
                if ($(this).prop('checked')) { checked = true; }
                $('.catalog .filters .filter-group .device-sizes input').prop('checked', false);
                if (checked) {  $(this).prop('checked', true); }
            }
            loadCatalog();
        });
    }







    if ($('.facebook-feed').length) {
        $.request('onGetFacebookPosts', {
            complete: function() {
                if ($('.facebook-post').length) {
                    ScrollTrigger.matchMedia({
                        // large
                        "(min-width: 992px)": function() {
                            gsap.set(".facebook-feed .facebook-post", {opacity: 0, scale: 0.5, top:200});
                            ScrollTrigger.batch(".facebook-feed .facebook-post", {
                                onEnter: batch => {
                                    gsap.to(batch, {
                                        opacity: 1, scale: 1, top:0, stagger: 0.1, duration: 0.5,
                                    })
                                },
                                onEnterBack: false
                            });
                        }
                    });

                    var $grid = $('.facebook-feed .posts').masonry({
                        itemSelector: '.post',
                        percentPosition: true
                    });

                    $grid.imagesLoaded().progress( function() {
                        $grid.masonry('layout');
                    });
                }
            }
        })
    }

    if ($('#appointment_form').length) {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (params.location_id) {
            $('#appointment_form .select2').val(params.location_id);
        }
    }


    if ($('.marketing-carousel').length) {
        $('.marketing-carousel').slick({
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
        });
    }

    function toggleFilterAccordions() {
        if ($('#filter.accordion')) {
            if (window.innerWidth >= 992) {
                $('#filters-details').collapse("show");
            }

            if (window.innerHeight >= 900) {
                $('#filters-details .filter-group .accordion-collapse').collapse("show");
            }
        }

        if ($('#hours.accordion')) {
            if (window.innerWidth >= 992) {
                $('#hours-details').collapse("show");
            }
        }
    }

    function getCatalogFilters() {
        var filters = {};
        $('.catalog .filters .filter-group').each(function() {
            var enabledFilters = [];
            $(this).find('input:checked').each(function() {
                enabledFilters.push($(this).val());
            });

            if (enabledFilters.length) {
                filters[$(this).data('filter-group')] = enabledFilters;
            }
        });

        return filters;
    }
});

